
let LOGIN_CHANNEL_ID 	= '1654801852'
let callback 			= 'https://cheflog.jp/linebot/id_check.php'

export default {
	/**
	 * LINEログイン用のURLを作成
	 * @param reUrl			リダイレクト用のURL
	 * @returns {string}
	 */
	loginUrl: function(reUrl) {
		let url = 'https://access.line.me/oauth2/v2.1/authorize?response_type=code&client_id='
			+ LOGIN_CHANNEL_ID
			+ '&redirect_uri='+encodeURIComponent(callback)
			+ '&state='+encodeURIComponent(reUrl)
			+ '&bot_prompt=normal&scope=profile%20openid%20email';
		return url
	}
}
