<template>
  <div>
    <v-snackbar v-model="snackbar" :timeout="snackbarTimeout" color="error" top>
      {{ snackbarText }}
      <v-btn text @click="snackbar = false">Close</v-btn>
    </v-snackbar>
    <v-container fluid>
      <v-row align="center" justify="center">
        <v-col cols="12" xs="12" sm="12" md="12" lg="4" xl="4">
          <!-- ___________________________________________________________________________予約情報  -->
          <div v-if="(!mode)">
            <h2>予約情報</h2>
            <v-form
                ref="form"
                v-model="valid"
                lazy-validation
            >
              <v-card
                  class="mx-auto mb-5"
                  outlined
              >
                <v-card-title>ご予約情報</v-card-title>
                <v-card-text>
                  <v-list-item two-line>
                    <v-list-item-content>
                      <v-list-item-title>ステータス</v-list-item-title>
                      <v-list-item-subtitle>
                        <span v-if="reserves.cancel">
                          キャンセル（{{reserves.cancel_on}}）
                        </span>
                        <span v-if="!reserves.cancel">
                          予約済み
                        </span>

                      </v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                  <v-list-item two-line>
                    <v-list-item-content>
                      <v-list-item-title>ご予約番号</v-list-item-title>
                      <v-list-item-subtitle>{{ reserves.no }}</v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>

                  <v-list-item two-line>
                    <v-list-item-content>
                      <v-list-item-title>ご予約日</v-list-item-title>

                      <v-list-item-subtitle>{{ reserves.reservation_date | japaneseDate }}</v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>

                  <v-list-item two-line>
                    <v-list-item-content>
                      <v-list-item-title>時間</v-list-item-title>
                      <v-list-item-subtitle>{{ reserves.reservation_time }}</v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>

                  <v-list-item two-line>
                    <v-list-item-content>
                      <v-list-item-title>人数</v-list-item-title>
                      <v-list-item-subtitle>{{ reserves.people }}</v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>

                  <v-list-item two-line>
                    <v-list-item-content>
                      <v-list-item-title>コース</v-list-item-title>
                      <v-list-item-subtitle>{{ reserves.reservation_course }}</v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>

                  <v-list-item two-line>
                    <v-list-item-content>
                      <v-list-item-title>特記事項</v-list-item-title>
                      <v-list-item-subtitle>{{ reserves.note }}</v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                </v-card-text>

                <v-divider class="mx-4"></v-divider>
                <v-card-title>お客様情報</v-card-title>
                <v-card-text>
                  <v-list-item two-line>
                    <v-list-item-content>
                      <v-list-item-title>お名前</v-list-item-title>
                      <v-list-item-subtitle>{{ reserves.name }}</v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>

                  <v-list-item two-line>
                    <v-list-item-content>
                      <v-list-item-title>電話番号</v-list-item-title>
                      <v-list-item-subtitle><a href="tel:">{{ reserves.tel }}</a></v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>

                  <v-list-item two-line>
                    <v-list-item-content>
                      <v-list-item-title>メールアドレス</v-list-item-title>
                      <v-list-item-subtitle>{{ reserves.usermail }}</v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                </v-card-text>

                <v-divider class="mx-4"></v-divider>
                <v-card-title>食べ物に関して</v-card-title>
                <v-card-text>
                  <v-list-item two-line>
                    <v-list-item-content>
                      <v-list-item-title>アレルギー</v-list-item-title>
                      <v-list-item-subtitle>{{ reserves.allergy }}</v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                  <v-list-item two-line>
                    <v-list-item-content>
                      <v-list-item-title>好きな食べ物</v-list-item-title>
                      <v-list-item-subtitle>{{ reserves.favorite_food }}</v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                  <v-list-item two-line>
                    <v-list-item-content>
                      <v-list-item-title>嫌いな食べ物</v-list-item-title>
                      <v-list-item-subtitle>{{ reserves.disliked_food }}</v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                  <v-list-item two-line>
                    <v-list-item-content>
                      <v-list-item-title>よく飲むお酒</v-list-item-title>
                      <v-list-item-subtitle>{{ reserves.alcohol_note }}</v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                </v-card-text>

                <v-divider class="mx-4"></v-divider>

                <v-card-title>お店に関して</v-card-title>
                <v-card-text>
                  <v-list-item two-line>
                    <v-list-item-content>
                      <v-list-item-title>好きな飲食</v-list-item-title>
                      <v-list-item-subtitle>{{ reserves.favorite_genre }}</v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                </v-card-text>
                <v-card-text>
                  <v-list-item two-line>
                    <v-list-item-content>
                      <v-list-item-title>よく行くお店</v-list-item-title>
                      <v-list-item-subtitle>{{ reserves.favorite_shop }}</v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                </v-card-text>
                <v-card-text>
                  <v-list-item two-line>
                    <v-list-item-content>
                      <v-list-item-title>利用目的</v-list-item-title>
                      <v-list-item-subtitle>{{ reserves.purpose }}</v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                </v-card-text>
                <v-card-text>
                  <v-list-item two-line>
                    <v-list-item-content>
                      <v-list-item-title>外食時の平均利用金額</v-list-item-title>
                      <v-list-item-subtitle>{{ reserves.average_amount | toThousandFilter }}円～</v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                </v-card-text>
              </v-card>


              <div align="center" class="ma-5">
                <v-btn
                    v-if="!reserves.cancel"
                    color="#e60044"
                    dark
                    depressed
                    tile
                    x-large
                    @click.stop="openCancelDialog(reserves)"
                >
                  キャンセルする
                </v-btn>
              </div>
            </v-form>
          </div>
          <!-- ___________________________________________________________________________予約情報  -->
          <div v-if="(mode == 'error')">
            <div align="center" class="ma-16">
              情報の取得に失敗しました。<br>
              <v-btn
                  class="ma-5"
                  dark
                  color="#1dcd00"
                  :href="lineURL"
              >LINE ログイン
              </v-btn>
            </div>
          </div>
        </v-col>
      </v-row>
    </v-container>
    <!-- _______________________________________ キャンセルdialog -->
    <v-dialog
        v-model="cancelDialog"
        max-width="600"
    >
      <v-card>
        <v-card-title class="headline">ご予約をキャンセルしますか？</v-card-title>

        <v-card-text>
          キャンセルするとキャンセル料が発生します<br/>
          <br/>
          3日前からのキャンセル：50%<br/>
          前日からのキャンセル：100%
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn
              color="#e60044"
              text
              @click="closeCancelDialog()"
          >
            キャンセルしない
          </v-btn>

          <v-btn
              color="#e60044"
              text
              @click="cancel(cancelDialogItem.no)"
          >
            キャンセルする
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>

</template>

<script>
import line from "@/vendor/line.js"
import reserver from "@/api/reserver.js";

export default {
  data() {
    return {
      valid: false,
      mode: '',

      // スネーク設定
      snackbar: false, 		// snackbar設定
      snackbarText: "", 		// 表示テキスト
      snackbarTimeout: 3000, 	// タイムアウト秒数

      // LINEログイン設定
      redirectUrl: '/shop_manage/reserve/',
      lineUrl: '', // LINEのログイン用URL

      // 予約情報
      reserves: [],
      reservesQuery: {
        token: this.$store.state.token,
        no: '',
        shopNum: '',
      },

      // キャンセルダイアログ
      cancelDialog: false,
      cancelDialogItem: [],
      shopNum:'',


    }
  },
  created() {

    console.log(this.reservesQuery.token)

    // 取得Tokenの確認
    if (this.$route.query.k) {
      this.$store.commit("SET_TOKEN", this.$route.query.k);
      this.$router.push(this.$route.path);
    }

    // LINEログインURLの作成
    this.lineURL = line.loginUrl(this.redirectUrl
        +this.$route.params.shopNum
        +"/"+this.$route.params.num)

    // トークンチェック
    if (!this.$store.state.token) {
      // LINEログインURLへリダイレクト
      window.location.href = this.lineURL
    }

    // 予約情報のクエリ設定
    this.reservesQuery.no = this.$route.params && this.$route.params.num
    this.reservesQuery.shopNum = this.$route.params && this.$route.params.shopNum

    // 予約情報の取得
    this.$nextTick(() => {
      reserver.fetchList(this.reserverInfo, this.reservesQuery);
    })


  },
  methods: {

    /** __________________________________________________________________________ 予約情報の取得 */
    async reserverInfo(info) {
      this.reserves = info.data;

      if (this.reserves.result == "error") {
        this.mode = "error"
      }

      console.log(this.reserves)
    },
    /** __________________________________________________________________________ キャンセルdialog */
    // Open
    openCancelDialog(item) {
      this.cancelDialog = true
      this.cancelDialogItem = item
    },
    // Close
    closeCancelDialog() {
      this.cancelDialog = false
      this.cancelDialogItem = []
    },
    /** __________________________________________________________________________ キャンセル処理 */
    cancel(num) {

      // パラメータ設定
      let params = new URLSearchParams();
      params.append("no", num);
      params.append("token", this.$store.state.token);
      params.append("shopNum", this.reservesQuery.shopNum);

      // キャンセル処理
      reserver.cancel(this.reserverCancel, params);
    },
    reserverCancel(res) {

      this.result = res.data.result;

      // エラー処理
      if (!this.result || (this.result == "error")) {
        switch (res.data.errorCode) {
          default:
            this.snackbarText = res.data.errorCode;
        }
        this.snackbar = true;
        stop();
      }

      // キャンセル成功
      if (this.result == "success") {

        // 予約情報の取得
        reserver.fetchList(this.reserverInfo, this.reservesQuery);
        this.cancelDialog = false

        // snackbar表示
        this.snackbarText = "ご予約をキャンセルしました";
        this.snackbar = true;
      }
    },
  },
  mounted() {

  }
}
</script>

<style scoped>

</style>
