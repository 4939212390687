import axios from "axios";

//接続URL
let url = process.env.VUE_APP_API_ORIGIN+"/api_reserver/list.php";
let cancelUrl = process.env.VUE_APP_API_ORIGIN+"/api_reserver/cancel.php";

export default {

	/**
	 * リスト取得
	 *
	 * @param callback
	 * @param query		no指定で単体取得
	 * @returns {Promise<void>}
	 */
	fetchList: async function(callback,query) {

		await axios.get(
			url,
			{
				params: query
			}
		)
			.then(response => callback(response));
	},
	/**
	 * キャンセル処理
	 * @param callback
	 * @param params
	 * @returns {Promise<void>}
	 */
	cancel: async function(callback,params) {
		await axios.post(cancelUrl, params)
			.then(response => callback(response));
	},
};
